
















































































import { IGetMessagesSearchParams, IMessage } from '@/interfaces';
import { dispatchGetMessagesSearch } from '@/store/admin/accessors';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class MessagesSearch extends Vue {
  public loading = false;
  public messages: IMessage[] = [];
  public totalMessages = 0;

  public date = '';
  public searchText = '';
  public sender = '';
  public receiver = '';

  public datePickerMenu = false;

  public pagination = {
    page: 1,
    rowsPerPage: 20,
    footerProps: { 'items-per-page-options': [10, 20, 50, 100] },
  };

  public async updateMessages(clearPagination = false) {
    if (clearPagination) {
      this.pagination.page = 1;
    }
    this.loading = true;
    const data = await this.getDataFromApi();
    this.totalMessages = data.total;
    this.messages = data.items;
    this.loading = false;
  }

  @Watch('pagination', { deep: true })
  public async onPaginationChange(val) {
    await this.updateMessages();
  }

  public get headers() {
    const headers = [
      {
        text: this.$vuetify.lang.t('$vuetify.inDate'),
        value: 'in_date',
        align: 'left',
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t('$vuetify.statusDate'),
        value: 'status_date',
        align: 'left',
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t('$vuetify.sender'),
        value: 'sender',
        align: 'left',
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t('$vuetify.receiver'),
        value: 'receiver',
        align: 'left',
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t('$vuetify.status'),
        value: 'status',
        align: 'left',
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t('$vuetify.messageText'),
        value: 'text',
        align: 'left',
        sortable: false,
      },
    ];
    return headers;
  }

  public async mounted() {
    await this.updateMessages();
  }

  public async getDataFromApi() {
    const { page, rowsPerPage } = this.pagination;
    const skip = (page - 1) * rowsPerPage;
    const params: IGetMessagesSearchParams = {
      skip,
      limit: rowsPerPage,
      date: this.date || null,
      receiver: this.receiver || null,
      search: this.searchText || null,
      sender: this.sender || null,
    };
    return await dispatchGetMessagesSearch(this.$store, params);
  }
}
