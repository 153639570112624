import { api } from '@/api';
import {
  IGetMessagesParams,
  IGetMessagesSearchParams,
  ISenderBanCreate,
  IServiceCreate,
  IServiceUpdate,
  IUserProfileCreate,
  IUserProfileUpdate,
} from '@/interfaces';
import { ISetting } from '@/interfaces/setting';
import { ActionContext } from 'vuex';
import { commitAddNotification, commitRemoveNotification, dispatchCheckApiError } from '../main/accessors';
import { State } from '../state';
import {
  commitSetSenderBans,
  commitSetService,
  commitSetServices,
  commitSetSettings,
  commitSetUser,
  commitSetUsers,
} from './accessors/commit';
import { AdminState } from './state';

type MainContext = ActionContext<AdminState, State>;

export const actions = {
  async actionGetUsers(context: MainContext) {
    try {
      const response = await api.getUsers(context.rootState.main.token);
      if (response) {
        commitSetUsers(context, response.data);
      }
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionGetUser(context: MainContext, id: number) {
    try {
      const response = await api.getUser(context.rootState.main.token, id);
      if (response) {
        commitSetUser(context, response.data);
      }
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionUpdateUser(context: MainContext, payload: { id: number; user: IUserProfileUpdate }) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateUser(context.rootState.main.token, payload.id, payload.user),
          await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'User successfully updated', color: 'success' });
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createUser(context.rootState.main.token, payload),
          await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'User successfully created', color: 'success' });
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionDeleteUser(context: MainContext, id: number) {
    try {
      const loadingNotification = { content: 'deleting', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.deleteUser(context.rootState.main.token, id),
          await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'User successfully deleted', color: 'success' });
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionDeleteUsers(context: MainContext, ids: number[]) {
    try {
      const loadingNotification = { content: 'deleting', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.deleteUsers(context.rootState.main.token, ids),
          await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Users successfully deleted', color: 'success' });
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },

  async actionGetMessagesStatistics(context: MainContext, data: { from_date: string; to_date: string }) {
    try {
      const response = await api.getMessagesStatistics(context.rootState.main.token, data.from_date, data.to_date);
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionGetMessagesByDay(context: MainContext, data: { from_date: string; to_date: string }) {
    try {
      const response = await api.getMessagesByDay(context.rootState.main.token, data.from_date, data.to_date);
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionGetMessages(context: MainContext, data: IGetMessagesParams) {
    try {
      const response = await api.getMessages(
        context.rootState.main.token,
        data.fromDate,
        data.toDate,
        data.receiver,
        data.sender,
        data.messageId,
        data.skip,
        data.limit,
        data.orderBy,
        data.descending,
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionGetMessagesSearch(context: MainContext, data: IGetMessagesSearchParams) {
    try {
      const response = await api.getMessagesSearch(
        context.rootState.main.token,
        data.date,
        data.receiver,
        data.search,
        data.sender,
        data.skip,
        data.limit,
      );
      return response.data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },

  async actionGetServices(context: MainContext) {
    try {
      const response = await api.getServices(context.rootState.main.token);
      if (response) {
        commitSetServices(context, response.data);
      }
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionGetService(context: MainContext, id: number) {
    try {
      const response = await api.getService(context.rootState.main.token, id);
      if (response) {
        commitSetService(context, response.data);
      }
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionCreateService(context: MainContext, payload: IServiceCreate) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createService(context.rootState.main.token, payload),
          await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetService(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Service successfully created', color: 'success' });
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionUpdateService(context: MainContext, payload: { id: number; service: IServiceUpdate }) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateService(context.rootState.main.token, payload.id, payload.service),
          await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetService(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Service successfully updated', color: 'success' });
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionDeleteService(context: MainContext, id: number) {
    try {
      const loadingNotification = { content: 'deleting', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.deleteService(context.rootState.main.token, id),
          await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Service successfully deleted', color: 'success' });
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionDeleteServices(context: MainContext, ids: number[]) {
    try {
      const loadingNotification = { content: 'deleting', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.deleteServices(context.rootState.main.token, ids),
          await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Services successfully deleted', color: 'success' });
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },

  async actionGetSenderBans(context: MainContext, payload: { skip: number; limit: number; sender: string }) {
    try {
      const response = await api.getSenderBans(
        context.rootState.main.token,
        payload.skip,
        payload.limit,
        payload.sender,
      );
      commitSetSenderBans(context, response.data.items);
      return response.data.total;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionDeleteSenderBan(context: MainContext, sender: string) {
    try {
      const loadingNotification = { content: 'deleting', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.deleteSenderBan(context.rootState.main.token, sender),
          await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Sender ban successfully deleted', color: 'success' });
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionDeleteSenderBans(context: MainContext, senders: string[]) {
    try {
      const loadingNotification = { content: 'deleting', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.deleteSenderBans(context.rootState.main.token, senders),
          await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Sender bans successfully deleted', color: 'success' });
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionCreateSenderBan(context: MainContext, payload: ISenderBanCreate) {
    try {
      const loadingNotification = { content: 'banning', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createSenderBan(context.rootState.main.token, payload),
          await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Sender successfully banned', color: 'success' });
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },

  async actionGetSettings(context: MainContext) {
    try {
      const response = await api.getSettings(context.rootState.main.token);
      commitSetSettings(context, response.data);
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
  async actionCreateSettings(context: MainContext, payload: ISetting[]) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createSettings(context.rootState.main.token, payload),
          await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Settings successfully saved', color: 'success' });
      return true;
    } catch (error) {
      await dispatchCheckApiError(context, error);
      return false;
    }
  },
};
