import { getStoreAccessors } from 'typesafe-vuex';
import { AdminState } from '../state';
import { State } from '@/store/state';
import { actions } from '../actions';

const { dispatch } = getStoreAccessors<AdminState, State>('');

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchGetUser = dispatch(actions.actionGetUser);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
export const dispatchDeleteUser = dispatch(actions.actionDeleteUser);
export const dispatchDeleteUsers = dispatch(actions.actionDeleteUsers);

export const dispatchCreateService = dispatch(actions.actionCreateService);
export const dispatchGetServices = dispatch(actions.actionGetServices);
export const dispatchGetService = dispatch(actions.actionGetService);
export const dispatchUpdateService = dispatch(actions.actionUpdateService);
export const dispatchDeleteService = dispatch(actions.actionDeleteService);
export const dispatchDeleteServices = dispatch(actions.actionDeleteServices);

export const dispatchGetMessagesStatistics = dispatch(actions.actionGetMessagesStatistics);
export const dispatchGetMessagesByDay = dispatch(actions.actionGetMessagesByDay);
export const dispatchGetMessages = dispatch(actions.actionGetMessages);
export const dispatchGetMessagesSearch = dispatch(actions.actionGetMessagesSearch);

export const dispatchGetSenderBans = dispatch(actions.actionGetSenderBans);
export const dispatchDeleteSenderBan = dispatch(actions.actionDeleteSenderBan);
export const dispatchDeleteSenderBans = dispatch(actions.actionDeleteSenderBans);
export const dispatchCreateSenderBan = dispatch(actions.actionCreateSenderBan);

export const dispatchGetSettings = dispatch(actions.actionGetSettings);
export const dispatchCreateSettings = dispatch(actions.actionCreateSettings);
