export default {
  close: 'Закрыть',
  dataIterator: {
    noResultsText: 'Не найдено подходящих записей',
    loadingText: 'Запись загружается...',
  },
  dataTable: {
    itemsPerPageText: 'Строк на странице:',
    ariaLabel: {
      sortDescending: ': Упорядочено по нисходящей. Активируйте, чтобы убрать сортировку.',
      sortAscending: ': Упорядочено во восходящей. Активируйте для упорядочивания по ниcходящей.',
      sortNone: ': Не упорядочено. Активируйте для упорядочивания по восходящей.',
    },
    sortBy: 'Сортировать по',
  },
  dataFooter: {
    itemsPerPageText: 'Записей на странице:',
    itemsPerPageAll: 'Все',
    nextPage: 'Следующая страница',
    prevPage: 'Предыдущая страница',
    firstPage: 'Первая страница',
    lastPage: 'Последняя страница',
    pageText: '{0}-{1} из {2}',
  },
  datePicker: {
    itemsSelected: '{0} выбран',
  },
  noDataText: 'Отсутствуют данные',
  carousel: {
    prev: 'Предыдущий визуальный',
    next: 'Следующий визуальный',
  },
  calendar: {
    moreEvents: 'Еще {0}',
  },
  fileInput: {
    counter: 'Файлов: {0}',
    counterSize: 'Файлов: {0} (всего {1})',
  },
  mainMenu: 'Главное меню',
  dashboard: 'Дашборд',
  profile: 'Профиль',
  changePassword: 'Сменить Пароль',
  admin: 'Админ',
  users: 'Пользователи',
  services: 'Сервисы',
  service: 'Сервис',
  allowedSenders: 'Допустимые отправители',
  callbackUrl: 'Колбек',
  callbackToken: 'Токен',
  callbackTokenHeader: 'Хедер токена',
  logout: 'Выйти',
  collapse: 'Скрыть',
  localeChange: 'English',
  login: 'Логин',
  password: 'Пароль',
  forgotPassword: 'Забыли пароль?',
  authorize: 'Войти',
  incorrectAuth: 'Некорректный логин или пароль',
  deleteUsersConfirmation: 'Вы уверен, что хотите удалить выбранных пользователей?',
  name: 'Имя',
  email: 'Email',
  fullName: 'Полное имя',
  title: 'Название',
  externalName: 'Внешнее название',
  isActive: 'Активен',
  userIsActive: 'Пользователь активен',
  userIsSuperuser: 'Пользователь админ',
  images: 'Картинки',
  allowed: 'Разрешены',
  notAllowed: 'Не разрешены',
  yes: 'Да',
  no: 'Нет',
  isSuperuser: 'Админ',
  actions: 'Действия',
  manageUsers: 'Управления Пользователями',
  createUser: 'Создать Пользователя',
  edit: 'Редактировать',
  editProfile: 'Редактировать Профиль',
  delete: 'Удалить',
  welcome: 'Добро пожаловать',
  passBadMessages: 'Пропускать плохие сообщения',
  currentlyPassBadMessages: 'пропускать',
  currentlyNotPassBadMessages: 'не пропускать',
  currentlyIgnoreUserBans: 'игнорировать',
  currentlyNotIgnoreUserBans: 'не игнорировать',
  currentlyActive: 'активен',
  currentlyNotActive: 'неактивен',
  currentlySuperuser: 'админ',
  currentlyNotSuperuser: 'не админ',
  setPassword: 'Пароль',
  confirmPassword: 'Подтвердите Пароль',
  cancel: 'Отмена',
  reset: 'Сброс',
  save: 'Сохранить',
  editUser: 'Редактировать Пользователя',
  user: 'Пользователь',
  deleteServicesConfirmation: 'Вы уверен, что хотите удалить выбранные сервисы?',
  manageServices: 'Управления Сервисами',
  createService: 'Создать Сервис',
  editService: 'Редактировать Сервис',
  messages: 'Сообщения',
  statistics: 'Статистика',
  messagesStatistics: 'Статистика по отправке сообщений',
  sendMessage: 'Отправить сообщение',
  send: 'Отправить',
  messageText: 'Текст сообщения',
  imageUrl: 'Ссылка на картинку',
  imagesAllowed: 'Картинки разрешены',
  isEmail: 'Для email',
  receivers: 'Получатели',
  receiversHint: 'Номер телефона получателя в формате E.164, рездлитель - новая строка',
  receiversFile: 'Список получателей в файле',
  receiversFileHint:
    'Формат .xlsx или .csv, без заголовка, один номер на строку в первом столбце, номера в формате E164',
  sender: 'Отправитель',
  receiverPhone: 'Номер телефона получателя',
  countryCode: 'Код страны',
  status: 'Статус',
  error: 'Ошибка',
  created: 'Создано',
  searchByPhoneNumber: 'Поиск по номеру телефона',
  receiver: 'Получатель',
  chooseService: 'Выберите сервис',
  management: 'Управление',
  manageMessages: 'Управление сообщениями',
  moSenderBans: 'Баны MO отправителей',
  manageSenderBans: 'Управление банами отправителей',
  phoneNumber: 'Номер телефона',
  banDuration: 'Длительность бана',
  createSenderBan: 'Забанить отправителя',
  manageSettings: 'Управление настройками',
  settings: 'Настройки',
  saveSettings: 'Сохранить настройки',
  key: 'Ключ',
  value: 'Значение',
  type: 'Тип',
  ban: 'Забанить',
  commentary: 'Комментарий',
  new: 'Новые',
  queued: 'В очереди',
  sent: 'Отправлено',
  delivered: 'Доставлено',
  read: 'Прочитано',
  thisMonth: 'Текущий месяц',
  period: 'Период',
  serviceCode: 'Код сервиса',
  date: 'Дата',
  timezone: 'Часовой пояс',
  scheduledDate: 'Плановая дата',
  newsletter: 'Рассылка',
  run: 'Запустить',
  runNewsletter: 'Запустить рассылку',
  editNewsletter: 'Редактировать рассылку',
  manageNewsletters: 'Управление рассылками',
  isTZConsidered: 'Учитывать TZ получателя',
  deleteNewsletterConfirmation: 'Вы уверен, что хотите удалить рассылку?',
  messageId: 'ID сообщения',
  newslettersMessages: 'Сообщения рассылки',
  newslettersName: 'Название рассылки',
  blacklists: 'Стоплист',
  manageBlacklists: 'Управление стоплистами',
  createBlacklist: 'Создать стоплист',
  blacklistedAddresses: 'Заблокированные адреса',
  editBlacklist: 'Редактировать стоплист',
  deleteBlacklistsConfirmation: 'Вы уверен, что хотите удалить выбранные стоплисты?',
  address: 'Адрес',
  addresses: 'Адреса',
  addressesFileHint: 'Номер телефона получателя в формате E.164, рездлитель - новая строка',
  addressesFile: 'Список адресатов в файле',
  manageSeqSends: 'Управление каскадными рассылками',
  createSeqSend: 'Создать каскадную рассылку',
  deleteSeqSendConfirmation: 'Вы уверен, что хотите удалить каскадную рассылку?',
  seqSends: 'Каскадная рассылка',
  selected: 'Выбрано',
  back: 'Назад',
  continue: 'Продолжить',
  notSet: 'Не указано',
  setTime: 'Указать время',
  formatHHMMSS: 'Формат: чч:мм:сс',
  selectServices: 'Выбирете сервисы',
  requiredField: '*Обязательное поле',
  searchMessages: 'Поиск сообщений',
  inDate: 'Дата приёма',
  statusDate: 'Дата доставки',
};
